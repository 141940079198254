import React from 'react'
import tw, { styled } from 'twin.macro'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import SmallPost from '../components/article-preview'

const Wrapper = styled.div`
  ${tw`w-full lg:w-3/4 xl:w-3/4 py-10 lg:py-20 mx-auto`};
`
const Body = styled.div`
  ${tw`border-t-[1px] border-l-0 border-r-0 border-b-0 border-solid border-grey-light dark:border-grey mt-10`};
`
const Title = styled.h2`
  ${tw`text-black dark:text-white font-normal text-4xl md:text-5xl`};
`
const Subtitle = styled.span`
  ${tw`text-lg`};
`

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    return (
      <>
        <SEO
          title="Articles | James Does Digital"
          description="Selected articles about software development, cloud computing and more"
          keywords={[`cloud`, `blog`, `react`, `aws`, `graphql`, `rails`]}
        />
        <Wrapper>
          <header>
            <Title>Articles</Title>
            <Subtitle>
              Selected articles about software development, cloud computing,
              DevOps and more.
            </Subtitle>
          </header>
          <Body>
            {posts.map(({ node }) => {
              return <SmallPost article={node} key={node.slug} />
            })}
          </Body>
        </Wrapper>
      </>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogQuery {
    allContentfulBlogPost(
      filter: { draft: { ne: true } }
      sort: { fields: [publishDate], order: [DESC] }
      limit: 10
    ) {
      edges {
        node {
          title
          titleAbridged
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            gatsbyImageData(
              quality: 90
              width: 800
              height: 452
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
            )
          }
          description
        }
      }
    }
  }
`
