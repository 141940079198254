import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'

const Wrapper = styled.article`
  ${tw`w-full py-4 border-t-0 border-l-0 border-r-0 border-b-[1px] border-solid border-grey-light 
  flex items-center flex xl:flex-row flex-col-reverse xl:flex-wrap
  dark:border-grey-dark
  `};
`
const TextWrapper = styled.div`
  ${tw`pr-2 w-full xl:w-2/3 md:pr-16`};
`
const Title = styled.h3`
  ${tw`leading-normal py-0 my-2 text-xl !font-sans font-semibold`};
`
const Anchor = styled(Link)`
  ${tw`leading-normal py-0 my-3`};
`
const Description = styled.p`
  ${tw`my-2`};
`
const PublicationDate = styled.p`
  ${tw`my-2 text-sm text-grey-darkest dark:text-grey-lightest`};
`
const ImageContainer = styled.div`
  ${tw`w-full xl:w-1/3`};
`
const Image = styled(GatsbyImage)`
  ${tw`rounded-sm`};
`

const PreviewArticle = ({ article }) => (
  <Wrapper>
    <TextWrapper>
      <Title>
        <Anchor to={`/articles/${article.slug}`}>
          {article.titleAbridged || article.title}
        </Anchor>
      </Title>
      <Description>{article.description}</Description>
      <PublicationDate>{article.publishDate}</PublicationDate>
    </TextWrapper>
    <ImageContainer>
      <Link to={`/articles/${article.slug}`}>
        <Image
          alt={article.title}
          title={article.title}
          image={article.heroImage.gatsbyImageData}
        />
      </Link>
    </ImageContainer>
  </Wrapper>
)

export default PreviewArticle
